import React from "react";
import _ from "lodash";
import { LoadingScreen } from "../components/Loading";
import P from "../components/P";
import connectedMinds from "../../resources/Connected_Minds.png";
import PathSection from "../components/PathSection";

const HomePage = (props) => {
  const { loading, allPaths, history } = props;
  if (loading) {
    return <LoadingScreen />;
  }

  return (
    <div className="home-page">
      <div className="home-page-splash flex-nowrap row d-flex pt-5">
        <div className="d-flex flex-column justify-content-center">
          <h1>Community-driven learning</h1>
          <h4 className="w-75 mb-4">
            Use Bifocl to <i>create, share, and discover</i> learning paths
            built from the best resources around the web.
          </h4>
          <a
            href="https://chrome.google.com/webstore/detail/bifocl/faojjhnoiidfljdmmaocoeopdlbfdnjj"
            target="_blank"
          >
            <button className="btn btn-primary mb-4">
              Add Extension to Chrome
            </button>
          </a>
          <P gray small>
            Download extension to easily share as you learn
          </P>
        </div>
        <div className="connected-minds">
          <img src={connectedMinds} />
        </div>
      </div>
      <div className="splash-divider" />
      <div>
        <PathSection title="Popular Paths" paths={allPaths} />
      </div>
    </div>
  );
};

export default HomePage;
