import React, { useState, useCallback, useEffect } from "react";
import update from "immutability-helper";
import _ from "lodash";
import { Link } from "react-router-dom";
import { MdBookmark, MdShare, MdAddCircleOutline } from "react-icons/md";
import { Helmet } from "react-helmet";
import ReactTooltip from "react-tooltip";
import { TiPencil } from "react-icons/ti";
import H2 from "../components/H2";
import H3 from "../components/H3";
import P from "../components/P";
import AddResourceModal from "./AddResourceModal";
import AddSectionModal from "./AddSectionModal";
import EditPathModal from "./EditPathModal";
import Avatar from "../components/Avatar";
import { LoadingScreen } from "../components/Loading";
import DraggableSection from "./DraggableSection";

const PathPage = (props) => {
  const {
    path,

    // Resource Modal
    resourceModalData,
    isAddResourceModalOpen,
    closeAddResourceModal,
    openAddResourceModal,
    openEditResourceModal,
    editResourceModalField,
    addResource,
    editResource,

    // Section Modal
    sectionModalData,
    isSectionModalOpen,
    closeSectionModal,
    openSectionModal,
    // TODO openEditSectionModal,
    editSectionModalField,
    addSection,
    // TODO: editSection,

    // Edit Path Modal
    editPathModalData,
    isEditPathModalOpen,
    closeEditPathModal,
    onEditPathModalField,
    openEditPathModal,

    publishPath,
    editPath,
    bookmarkPath,
    unBookmarkPath,
    deleteResource,
    saveCardReorder,
    saveSectionReorder,
    user,
  } = props;

  if (path) {
    const {
      title,
      description,
      creator,
      sections,
      isBookmarked,
      bookmarkCount,
    } = path;

    const isPathOwner = user.id === path.creator.id;
    const [orderedSections, setSectionOrder] = useState(sections);

    useEffect(() => {
      if (
        !_.isEqual(_.sortBy(sections, "id"), _.sortBy(orderedSections, "id"))
      ) {
        setSectionOrder(sections);
      }
    });

    const reorderSections = useCallback(
      (dragIndex, hoverIndex) => {
        const dragSection = orderedSections[dragIndex];
        const newOrder = update(orderedSections, {
          $splice: [[dragIndex, 1], [hoverIndex, 0, dragSection]],
        });
        console.log(newOrder);
        setSectionOrder(newOrder);
        saveSectionReorder(newOrder);
      },
      [orderedSections]
    );

    return (
      <React.Fragment>
        <div className="path-page pb-5">
          <div className="header-section col-md-12">
            <div className="title-and-actions row d-flex justify-content-between mb-4">
              <div className="title">
                <H2>{title}</H2>
              </div>
              <div className="action-buttons d-flex pt-1">
                {isPathOwner ? (
                  <div className="icon mr-3" onClick={openEditPathModal}>
                    <TiPencil />
                  </div>
                ) : null}
                <div
                  className="icon mr-3"
                  data-tip="Sharing paths coming soon..."
                >
                  <MdShare />
                </div>
                <div
                  className={`icon bookmark ${
                    isBookmarked ? "highlighted" : ""
                  } mr-2`}
                  onClick={isBookmarked ? unBookmarkPath : bookmarkPath}
                >
                  <MdBookmark />
                </div>
                <div className="bookmark-count">
                  {bookmarkCount || 0}{" "}
                  <div className="web d-inline">Bookmarks</div>
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-between">
              <div className="w-100">
                <div className="row d-flex justify-content-between">
                  <Link
                    className="d-flex align-items-center mb-1"
                    to={`/user/${path.creator.id}`}
                  >
                    <Avatar avatarUrl={_.get(creator, "avatarUrl")} />
                    <div className="ml-3">
                      <P big>{_.get(creator, "name")}</P>
                    </div>
                  </Link>
                  {isPathOwner && !path.isPublished ? (
                    <div>
                      <button
                        className="btn btn-primary"
                        onClick={publishPath}
                        // TODO disabled={areNoResources}
                      >
                        Publish
                      </button>
                    </div>
                  ) : null}
                </div>
                <div className="row mb-2 description">
                  <P big gray light>
                    {description}
                  </P>
                </div>
              </div>
            </div>
          </div>
          <div className="content-section mt-4">
            {isPathOwner ? (
              <button
                className="add-section-button btn-no-dflt"
                onClick={openSectionModal}
              >
                <div className="glow">
                  <MdAddCircleOutline />
                </div>
                <H3>Add Section</H3>
              </button>
            ) : null}
            {_.map(orderedSections, (section, i) => {
              return (
                <DraggableSection
                  section={section}
                  defaultOpen={i == 0}
                  i={i}
                  index={i}
                  key={i}
                  isPathOwner={isPathOwner}
                  openAddResourceModal={openAddResourceModal}
                  openEditResourceModal={openEditResourceModal}
                  deleteResource={deleteResource}
                  moveSection={reorderSections}
                  saveCardReorder={(resources) =>
                    saveCardReorder(resources, section.id)
                  }
                />
              );
            })}
          </div>
        </div>
        <AddSectionModal
          show={isSectionModalOpen}
          closeModal={closeSectionModal}
          modalData={sectionModalData}
          onEditField={editSectionModalField}
          addSection={addSection}
          // editSection={editSection}
          isInEditMode={!!sectionModalData.sectionId}
        />
        <AddResourceModal
          show={isAddResourceModalOpen}
          closeModal={closeAddResourceModal}
          modalData={resourceModalData}
          onEditField={editResourceModalField}
          addResource={addResource}
          editResource={editResource}
          isInEditMode={!!resourceModalData.resourceId}
        />
        <EditPathModal
          show={isEditPathModalOpen}
          closeModal={closeEditPathModal}
          modalData={editPathModalData}
          onEditField={onEditPathModalField}
          onSave={editPath}
        />
        <ReactTooltip place="top" effect="solid" />
        <Helmet>
          <title>{title} - Path - Bifocl</title>
        </Helmet>
      </React.Fragment>
    );
  }

  return <LoadingScreen />;
};

export default PathPage;
