import { graphql } from "react-apollo";
import gql from "graphql-tag";
import Fragments from "./fragments";
import _ from "lodash";

export const GET_PATH_QUERY = gql`
  query getPath($id: ID!) {
    path(id: $id) {
      ...FullPath
    }
  }
  ${Fragments.path.full}
`;

export const getPath = graphql(GET_PATH_QUERY, {
  options: (props) => ({
    variables: { id: _.get(props, "match.params.id") },
  }),
  props: ({ data: { loading, error, refetch, path } }) => ({
    loading,
    error,
    refetchPath: refetch,
    path,
  }),
});

export const GET_ALL_PATHS = gql`
  query getAllPaths {
    allPaths {
      ...MiniPath
    }
  }
  ${Fragments.path.mini}
`;

export const getAllPaths = graphql(GET_ALL_PATHS, {
  props: ({ data: { loading, error, allPaths } }) => ({
    loading,
    error,
    allPaths,
  }),
});

// export const GET_HOME_PAGE = gql`
//   query getHomePage {
//     homePage {
//       ...MiniPath
//     }
//   }
//   ${Fragments.path.mini}
// `;

// export const getHomePage = graphql(GET_HOME_PAGE, {
//   props: ({ data: { loading, error, homePage } }) => ({
//     loading,
//     error,
//     homePage,
//   }),
// });

const CREATE_PATH_MUTATION = gql`
  mutation createPath($path: CreatePathInput!) {
    createPath(path: $path) {
      ...MiniPath
    }
  }
  ${Fragments.path.mini}
`;

export const createPath = graphql(CREATE_PATH_MUTATION, {
  props: ({ mutate }) => ({
    createPath: (path) => {
      return mutate({
        variables: { path },
        //   update: (cache, { data: { createPath } }) => {
        //     const data = cache.readQuery({
        //       query: GET_HOME_PAGE,
        //     });

        //     const categories = data.homePage;

        //     const category = _.find(
        //       categories,
        //       (cat) => cat[0] && cat[0].category === createPath.category
        //     );

        //     if (category) {
        //       category.push(createPath);
        //     } else {
        //       categories.push([createPath]);
        //     }
        //     cache.writeQuery({
        //       query: GET_HOME_PAGE,
        //       data,
        //     });
        //   },
      });
    },
  }),
});

const PUBLISH_PATH_MUTATION = gql`
  mutation publishPath($pathId: ID!) {
    publishPath(pathId: $pathId) {
      id
      isPublished
    }
  }
`;

export const publishPath = graphql(PUBLISH_PATH_MUTATION, {
  props: ({ mutate }) => ({
    publishPath: (path) => {
      return mutate({
        variables: { pathId: path.id },
        optimisticResponse: {
          __typename: "MUTATION",
          publishPath: {
            id: path.id,
            isPublished: true,
            __typename: "PATH",
          },
        },
      });
    },
  }),
});

const EDIT_PATH_MUTATION = gql`
  mutation editPath($pathId: ID!, $pathChanges: CreatePathInput) {
    editPath(pathId: $pathId, pathChanges: $pathChanges) {
      ...FullPath
    }
  }
  ${Fragments.path.full}
`;

export const editPath = graphql(EDIT_PATH_MUTATION, {
  props: ({ mutate }) => ({
    editPath: (path, pathChanges) => {
      return mutate({
        variables: {
          pathId: path.id,
          pathChanges,
        },
        optimisticResponse: {
          __typename: "MUTATION",
          editPath: {
            ...path,
            ...pathChanges,
          },
        },
      });
    },
  }),
});

const UNBOOKMARK_PATH_MUTATION = gql`
  mutation unBookmarkPath($pathId: ID!) {
    unBookmarkPath(pathId: $pathId) {
      ...FullPath
    }
  }
  ${Fragments.path.full}
`;

export const unBookmarkPath = graphql(UNBOOKMARK_PATH_MUTATION, {
  props: ({ mutate }) => ({
    unBookmarkPath: (path) => {
      return mutate({
        variables: {
          pathId: path.id,
        },
      });
    },
  }),
});

const BOOKMARK_PATH_MUTATION = gql`
  mutation bookmarkPath($pathId: ID!) {
    bookmarkPath(pathId: $pathId) {
      ...FullPath
    }
  }
  ${Fragments.path.full}
`;

export const bookmarkPath = graphql(BOOKMARK_PATH_MUTATION, {
  props: ({ mutate }) => ({
    bookmarkPath: (path) => {
      return mutate({
        variables: {
          pathId: path.id,
        },
      });
    },
  }),
});

const ADD_SECTION_MUTATION = gql`
  mutation addSection($pathId: ID!, $section: SectionInput!) {
    addSection(pathId: $pathId, section: $section) {
      ...FullPath
    }
  }
  ${Fragments.path.full}
`;

export const addSection = graphql(ADD_SECTION_MUTATION, {
  props: ({ mutate }) => ({
    addSection: (pathId, section) => {
      return mutate({
        variables: {
          pathId,
          section,
        },
      });
    },
  }),
});

const ADD_RESOURCE_MUTATION = gql`
  mutation addResource(
    $pathId: ID!
    $sectionId: ID!
    $resource: ResourceInput!
    $isFromExtension: Boolean
  ) {
    addResource(
      pathId: $pathId
      sectionId: $sectionId
      resource: $resource
      isFromExtension: $isFromExtension
    ) {
      ...FullPath
    }
  }
  ${Fragments.path.full}
`;

export const addResourceFromExtension = graphql(ADD_RESOURCE_MUTATION, {
  props: ({ mutate }) => ({
    addResource: (pathId, resource) => {
      return mutate({
        variables: {
          pathId,
          resource,
          isFromExtension: true,
        },
      });
    },
  }),
});

export const addResource = graphql(ADD_RESOURCE_MUTATION, {
  props: ({ mutate }) => ({
    addResource: (path, sectionId, resource) => {
      // const { id, resources } = path;
      // const optimisticResource = _.clone(resource);
      // optimisticResource.__typename = "Resource";
      // optimisticResource.id = "2";
      // optimisticResource.previewUrl =
      //   "https://media.giphy.com/media/3oEjI6SIIHBdRxXI40/giphy.gif";
      return mutate({
        variables: { pathId: path.id, sectionId, resource },
        // optimisticResponse: {
        //   __typename: "MUTATION",
        //   addResource: {
        //     __typename: "Path",
        //     ...path,
        //     resources: resources.concat([optimisticResource]),
        //   },
        // },
        // update: (cache, { data: { addResource } }) => {
        //   cache.writeQuery({
        //     query: GET_PATH_QUERY,
        //     variables: {
        //       id,
        //     },
        //     data: { path: addResource },
        //   });
        // },
      });
    },
  }),
});

const EDIT_RESOURCE_MUTATION = gql`
  mutation editResource($resourceId: ID!, $resourceChanges: ResourceInput) {
    editResource(resourceId: $resourceId, resourceChanges: $resourceChanges) {
      ...FullResource
    }
  }
  ${Fragments.resources.full}
`;

export const editResource = graphql(EDIT_RESOURCE_MUTATION, {
  props: ({ mutate }) => ({
    editResource: (resource) => {
      // const optimisticResource = _.clone(resource);
      // optimisticResource.__typename = "Resource";
      const resourceId = resource.id;
      delete resource["id"];
      // delete resource["previewUrl"];
      return mutate({
        variables: { resourceId, resourceChanges: resource },
        // optimisticResponse: {
        //   __typename: "MUTATION",
        //   editResource: {
        //     ...optimisticResource,
        //   },
        // },
      });
    },
  }),
});

const DELETE_RESOURCE_MUTATION = gql`
  mutation deleteResource($resourceId: ID!) {
    deleteResource(resourceId: $resourceId) {
      ...FullResource
    }
  }
  ${Fragments.resources.full}
`;

export const deleteResource = graphql(DELETE_RESOURCE_MUTATION, {
  props: ({ mutate }) => ({
    deleteResource: (resourceId) => {
      return mutate({
        variables: { resourceId },
      });
    },
  }),
});

const REORDER_SECTIONS_MUTATION = gql`
  mutation reorderSections($pathId: ID!, $orderedSectionIds: [ID]!) {
    reorderSections(pathId: $pathId, orderedSectionIds: $orderedSectionIds) {
      ...FullPath
    }
  }
  ${Fragments.path.full}
`;

export const reorderSections = graphql(REORDER_SECTIONS_MUTATION, {
  props: ({ mutate }) => ({
    reorderSections: (pathId, orderedSectionIds) => {
      return mutate({
        variables: { pathId, orderedSectionIds },
      });
    },
  }),
});

const REORDER_RESOURCES_MUTATION = gql`
  mutation reorderResources(
    $pathId: ID!
    $sectionId: ID!
    $orderedResourceIds: [ID]!
  ) {
    reorderResources(
      pathId: $pathId
      sectionId: $sectionId
      orderedResourceIds: $orderedResourceIds
    ) {
      ...FullPath
    }
  }
  ${Fragments.path.full}
`;

export const reorderResources = graphql(REORDER_RESOURCES_MUTATION, {
  props: ({ mutate }) => ({
    reorderResources: (pathId, sectionId, orderedResourceIds) => {
      return mutate({
        variables: { pathId, sectionId, orderedResourceIds },
      });
    },
  }),
});
