import React from "react";
import PropTypes from "prop-types";
import Modal from "../components/Modal";
import Input from "../components/Input";
import Textarea from "../components/Textarea";
import { LoadingIcon } from "../components/Loading";

const AddSectionModal = (props) => {
  const {
    modalData,
    show,
    closeModal,
    onEditField,
    addSection,
    editSection,
    isInEditMode,
  } = props;
  const { sectionId, title, description, isAddingSection } = modalData;

  const onSave = () => {
    isInEditMode
      ? editSection({
          id: sectionId,
          title,
          description,
        })
      : addSection({
          title,
          description,
        });
  };

  return (
    <Modal
      header={isInEditMode ? "Edit Section" : "Add Section"}
      show={show}
      onClose={closeModal}
    >
      <div className="mt-1">
        <div className="mt-2">
          <Input
            value={title}
            placeholder="Title*"
            onChange={(value) => onEditField({ field: "title", value })}
          />
        </div>
        <div className="mt-2">
          <Textarea
            placeholder="Description (optional)"
            value={description}
            onChange={(value) => editField({ field: "description", value })}
          />
        </div>
      </div>
      <div className="mt-3 d-flex justify-content-center">
        <button
          className="btn btn-primary px-4"
          onClick={onSave}
          disabled={!title || isAddingSection}
        >
          {isAddingSection ? <LoadingIcon /> : "save"}
        </button>
      </div>
    </Modal>
  );
};

AddSectionModal.propTypes = {
  show: PropTypes.bool,
  closeModal: PropTypes.func.isRequired,
  onEditField: PropTypes.func.isRequired,
  modalData: PropTypes.object,
};

export default AddSectionModal;
