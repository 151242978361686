import React from "react";
import _ from "lodash";
import { Link } from "react-router-dom";
import UserHeader from "./UserHeader";
import logo from "../../resources/logo.png";
import smallLogo from "../../resources/just_glasses.png";

const routes = [
  // {
  //   title: "Paths",
  //   link: "/paths",
  // },
  {
    title: "+ Create",
    link: "/create",
  },
];
const DropDownMenu = (props) => {
  const { show, user, onSelection, onLogout } = props;

  if (show) {
    return (
      <div className="user-menu mr-5">
        <Link onClick={onSelection} to={`/user/${user.id}`}>
          Profile
        </Link>
        <Link onClick={onLogout} to="/">
          Logout
        </Link>
      </div>
    );
  }
  return null;
};

const Header = (props) => {
  const currentRoute = _.get(props, "location.pathname");
  const { showUserMenu, toggleUserMenu, logout } = props;

  return (
    <div className="header">
      <div className="header-content">
        <div className="header-div">
          <Link className="header-logo" to="/">
            <img className="web mr-5" src={logo} />
            <img className="mobile" src={smallLogo} />
          </Link>
          {_.map(routes, (route) => {
            return (
              <Link
                className={currentRoute === route.link ? "selected-route" : ""}
                to={route.link}
                key={route.link}
              >
                {route.title}
              </Link>
            );
          })}
        </div>
        <div className="header-div right">
          <UserHeader {...props} onClick={toggleUserMenu} />
        </div>
      </div>
      <DropDownMenu
        show={showUserMenu}
        user={props.user}
        onSelection={toggleUserMenu}
        onLogout={logout}
      />
    </div>
  );
};

export default Header;
